import axios from "../axios";

const UserService = {
  requestNewPassword: async (email) => {
    return await axios
      .post(`/users/change-password-request/${email}`)
      .then(res => res.data)
  },
  confirmNewPassword: (token, email) => async (data) => {
    return await axios
      .put(`/users/change-password/${token}/${email}`, data)
      .then(res => res.data)
  },
  activatePartner: (token) => async () => {
    return await axios
      .post(`/users/activate/${token}`)
      .then(res => res.data)
  },
  changePasswordFirstTime: () => async (data) => {
    return await axios
      .put(`/users/panel-change-password`, data)
      .then(res => res.data)
  },

};

export default UserService;
