import axios from "../axios";

const AuthService = {
    CheckToken: async (token) => {
        return await axios
            .get("/users/me", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    return res.data;
                }
                throw Error("Bad response");
            })
            .then((res) => res);
    },
    Login: async ({ email, password }) => {
        return axios
            .post("/login", {
                email,
                password,
            })
            .then((res) => res.data);
    },
    Logout: () => {},
};

export default AuthService;
