import React from 'react'
import styles from '../Login/Login.module.scss'
import {
    CButton,
    CCardHeader,
    CForm,
    CFormGroup,
    CFormText,
    CInput,
    CCol,
    CRow,
    CCard,
    CCardBody,
    CInputGroupText, CInputGroupPrepend, CInputGroup
} from '@coreui/react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import UserService from '../../services/UserService'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import Loader from '../../components/Loader/Loader'
import Logo from "../../components/Logo/Logo";
import CIcon from "@coreui/icons-react";

const ConfirmNewPassword = ({ firstLogin }) => {
    const history = useHistory();
    const params = useParams();
    const { handleSubmit, register, formState: { errors } } = useForm();

    const [confirmPartner, { isLoading: isLoadingPasswordConfirmation }] = useMutation(
        UserService.confirmNewPassword(params.token, params.email),
        {
            onSuccess: () => {
                toast.success("Twoje hasło zostało zresetowane. Zaloguj się teraz.");
                history.push('/login');
            },
            onError: (e) => {
                if (e?.response?.data) {
                    for (const [key, value] of Object.entries(e.response.data)) {
                        console.log(key)
                        toast.error(value.message)
                    }
                } else {
                    toast.error("Ups... Coś poszło nie tak")
                }
            }
        }
    )

    const [changePasswordFirstTime, { isLoading: isLoadingChangePasswordFirstTime }] = useMutation(
        UserService.changePasswordFirstTime(),
        {
            onSuccess: () => {
                toast.success("Twoje hasło zostało zresetowane. Zaloguj się teraz.");
                history.push('/login');
            },
            onError: (e) => {
                if (e?.response?.data) {
                    console.log(e?.response?.data)

                    for (const [key, value] of Object.entries(e.response.data)) {
                        console.log(key)
                        toast.error(value.message)
                    }
                } else {
                    toast.error("Ups... Coś poszło nie tak")
                }
            }
        }
    )

    const onSubmit = async (data) => {
        if (firstLogin) {
            changePasswordFirstTime(data)
        }
        else {
            await confirmPartner(data)
        }
    }

    const isLoading = isLoadingPasswordConfirmation || isLoadingChangePasswordFirstTime

    return (
        <div className={styles.loginPage}>
            <CRow
                className={`${styles.container} align-items-center flex-fill vh-100 text-center`}
            >
                {
                    isLoading && <Loader show={true} />
                }
                <CCol className={styles.loginContainer}>
                    <CCard className={styles.card}>
                        <CCardHeader className={styles.header}>
                            <Logo />
                            <h4>Ustawianie nowego hasła</h4>
                        </CCardHeader>
                        <CCardBody className={styles.formContainer}>
                            <CForm onSubmit={handleSubmit(onSubmit)}>
                                <CFormGroup>
                                    <CInputGroup>
                                        <CInputGroupPrepend>
                                            <CInputGroupText>
                                                <CIcon name="cil-asterisk" />
                                            </CInputGroupText>
                                        </CInputGroupPrepend>
                                        <CInput
                                            type='password'
                                            id='raw_password'
                                            name="passwordPlainText"
                                            innerRef={register}
                                            placeholder="Wprowadź hasło"
                                        />
                                        <CFormText className="mt-3 text-right" color="danger">
                                            {errors.raw_password?.message}
                                        </CFormText>
                                    </CInputGroup>
                                </CFormGroup>
                                <CFormGroup>
                                    <CInputGroup>
                                        <CInputGroupPrepend>
                                            <CInputGroupText>
                                                <CIcon name="cil-asterisk" />
                                            </CInputGroupText>
                                        </CInputGroupPrepend>
                                        <CInput
                                            type='password'
                                            id='raw_password_repeat'
                                            name="passwordPlainTextRepeat"
                                            innerRef={register}
                                            placeholder="Potwierdź nowe hasło"
                                        />
                                        <CFormText className="mt-3 text-right" color="danger">
                                            {errors.raw_password_repeat?.message}
                                        </CFormText>
                                    </CInputGroup>
                                </CFormGroup>
                                <CFormGroup className={`${styles.formFooter} form-actions`}>
                                    <CButton type="submit" size="xl" color="primary" shape="square">
                                        Potwierdź
                                    </CButton>
                                </CFormGroup>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    )
}

export default ConfirmNewPassword
