import React from "react";
import PropTypes from "prop-types";
import LogoImage from './assets/logo.svg'
import LogoImageDev from './assets/logo_dev.png'

const Logo = function ({ width, height }) {
  return (
      <img src={ process.env.REACT_APP_API_URL && process.env.REACT_APP_API_URL.includes('//gruzexpress.pl') ? LogoImage : LogoImageDev }  alt="GZExpress logo" width={width} height={height} />
  );
};
Logo.defaultProps = {
  width: 180,
  height: 40,
};
Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Logo;
