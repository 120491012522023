import React, {useEffect} from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import userService from "../../services/UserService";
import Loader from "../../components/Loader/Loader";

const ActivatePartner = () => {
    const history = useHistory();
    const params = useParams();
    const [activatePartner, { isLoading: isLoadingActivatePartner }] = useMutation(
        userService.activatePartner(params.token),
        {
            onSuccess: () => {
                toast.success("Użytkownik został aktywowany");
                history.push('/login');
            },
            onError: (e) => {
                    toast.error("Nie udało się aktywować użytkownika")
                    history.push('/login');
            }
        }
    )
    useEffect(() => {
        activatePartner();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        isLoadingActivatePartner && <Loader show={true} />
    )
}

export default ActivatePartner
