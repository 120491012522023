import { useStoreActions } from "easy-peasy";
import { useEffect } from "react";
import { queryCache } from "../../App";
import {useHistory} from "react-router-dom";


const Logout = function () {
  const history = useHistory();
  const clearUser = useStoreActions((actions) => actions.auth.clearUser);
  useEffect(() => {
    clearUser();
    queryCache.clear();
    history.push(`/login`);
    // eslint-disable-next-line
  }, []);

  return null;
};

export default Logout;
