import { action, computed, persist, thunk } from "easy-peasy";
// import SCOPES from "../scopes";
import AuthService from "../services/AuthService";

const AuthStore = persist(
    {
        token: null,
        user: null,
        role: computed((state) => {
            return (state.user?.roles || [null])[0];
        }),
        setUser: action((state, { user, token = null }) => {
            state.user = user;
            if (token) {
                state.token = token;
            }
        }),
        isAuthenticated: computed((state) => {
            return !!state.user && !!state.token;
        }),
        isAdmin: computed((state) => {
            return state.role === "ROLE_ADMIN";
        }),
        clearUser: action((state) => {
            state.user = null;
            state.token = null;
        }),

        loginUser: thunk(async (actions, data) => {
            try {
                const res = await AuthService.Login(data);
                const user = await AuthService.CheckToken(res.token);
                actions.setUser({
                    user,
                    token: res.token
                });
                return res
            } catch (e) {
                throw new Error(e);
            }
        }),
    },
);

export default AuthStore;
