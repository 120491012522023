import { StoreProvider, useStoreRehydrated, useStoreState } from "easy-peasy";
import React from "react";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RequestNewPassword from "./views/Register/RequestNewPassword";
import ConfirmNewPassword from "./views/Register/ConfirmNewPassword";
import Loader from "./components/Loader/Loader";
import "./scss/style.scss";
import Login from "./views/Login/Login";
import Logout from "./views/Logout/Logout";
import stores from "./stores";
import AuthProvider from "./AuthProvider";
import ActivatePartner from "./views/Partner/ActivatePartner";



// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
export const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => {
        if (error?.response?.status === 401) {
          toast.warn("Token wygasł, zaloguj się ponownie", {
            toastId: 0,
          });
          stores.getActions().auth.clearUser();
        }
        if (error?.response?.status === 403) {
          toast.error("Brak uprawnień do wyświetlenia tej strony");
        }
      },
    },
  },
});

function PrivateRoute({ children, ...rest }) {
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);
  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
  return <Route {...rest} />;
}

const Routing = function () {
  const isRehydrated = useStoreRehydrated();
  if (!isRehydrated) return <Loader show />;

  return (
    <React.Suspense fallback={<Loader show />}>
      <Switch>
        <Route
          path="/login"
          name="Login"
          render={(props) => <Login {...props} />}
        />
        <Route
          path="/request-new-password"
          name="RequestNewPassword"
          render={(props) => <RequestNewPassword {...props} />}
        />

        <Route
          path="/user/password/change/:token/:email"
          name="Confirm New Password"
          render={(props) => <ConfirmNewPassword firstLogin={false} {...props} />}
        />
        <Route
          path="/user/password/change/:token/"
          name="Confirm New Password"
          render={(props) => <ConfirmNewPassword firstLogin={true} {...props} />}
        />
        <Route
          path="/activate/:token"
          name="Activate Partner"
          render={(props) => <ActivatePartner {...props} />}
        />
        <PrivateRoute
          path="/logout"
          name="Logout"
          render={(props) => <Logout {...props} />}
        />

        <PrivateRoute
          path="/"
          name="Home"
          render={(props) => <TheLayout {...props} />}
        />
      </Switch>
    </React.Suspense>
  );
};
const App = function () {
  return (
    <StoreProvider store={stores}>
      <Loader />
      <ToastContainer />
      <ReactQueryCacheProvider queryCache={queryCache}>
        <AuthProvider>
          <Router basename="/">
            <Routing />
          </Router>
        </AuthProvider>
      </ReactQueryCacheProvider>
    </StoreProvider>
  );
};

export default App;
