import { action } from "easy-peasy";

const AppStore = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
  loaderShow: false,
  setSidebarShow: action((state, payload) => {
    state.sidebarShow = payload;
  }),
  setAsideShow: action((state, payload) => {
    state.asideShow = payload;
  }),
  setDarkMode: action((state, payload) => {
    state.darkMode = payload;
  }),
  showLoader: action((state, payload) => {
    state.loaderShow = payload;
  }),
};

export default AppStore;
